import React from "react";
import { Container, Image, Row, Col } from "react-bootstrap";
 import { Link } from "react-router-dom";
import { easeOut, motion } from "framer-motion";
import schoolicons from "../../../../assets/schoolicons.svg";
import utilityicons from "../../../../assets/utilityicons.svg";
import commerceicon from "../../../../assets/commerceicon.svg";


import "../../homepage/homepage.css";

function Services() {
  return (
    <Container fluid className="services">
      <motion.div
        initial={{ opacity: 0, y: 40 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3, delay: 0.4, timingFunction: easeOut }}
        viewport={{ once: true }}
      >
        <center>
          <h3 className="howitworkhead">Our Services</h3>
          <p className="howitworkparagraph">
          Adept is a customizable solution that can be tailored around 
          any organizations payment collection needs.<br/> The top 3 use cases, 
          however, are;
          </p>
        </center>
      </motion.div>

      <Row className="servicesrow">
        <Col xs={12} md={4} lg={4}>
          <motion.div
            initial={{ opacity: 0, y: 40 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.4, timingFunction: easeOut }}
            viewport={{ once: true }}
          >
            <div className="howitworkcardbody">
              <Image src={schoolicons} fluid />

              <div style={{ marginTop: 1.2 + "rem" }}>
                <h6><strong>Adept for Education</strong></h6>
                <p className="howitworkeachparagraph">
                A4E is a payment system that lets schools and institutions give each student a unique account number (NUBAN) for fee collection.
                </p>
                <Link to="https://education.adept.ng">
                <span style={{color:'#F14810', fontSize:0.8+'rem'}}>Learn More</span>
                </Link>
              </div>
            </div>
          </motion.div>
        </Col>
        <Col xs={12} md={4} lg={4}>
          <motion.div
            initial={{ opacity: 0, y: 40 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.6, timingFunction: easeOut }}
            viewport={{ once: true }}
          >
            <div className="howitworkcardbody">
              <Image src={utilityicons} fluid />

              <div style={{ marginTop: 1.2 + "rem" }}>
              <h6><strong>Adept for Utilities</strong></h6>
                <p className="howitworkeachparagraph">
                A4U lets utility providers like electric companies, solar firms, TV providers, and others give each customer a unique (NUBAN) for bill payments.
                </p>
                <Link to="/ourservice#utility">
                <span style={{color:'#F14810', fontSize:0.8+'rem'}}>Learn More</span>
                </Link>
              </div>
            </div>
          </motion.div>
        </Col>
        <Col xs={12} md={4} lg={4}>
          <motion.div
            initial={{ opacity: 0, y: 40 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.8+0.2, timingFunction: easeOut }}
            viewport={{ once: true }}
          >
            <div className="howitworkcardbody">
              <Image src={commerceicon} fluid />

              <div style={{ marginTop: 1.2 + "rem" }}>
              <h6><strong>Adept for Commerce</strong></h6>
                <p className="howitworkeachparagraph">
                A4C offers organizations a way to create NUBAN account numbers for clients and distributors, simplifying recurring payments.
                </p>
                <Link to="/ourservice#utility">
                <span style={{color:'#F14810', fontSize:0.8+'rem'}}>Learn More</span>
                </Link>
              </div>
            </div>
          </motion.div>
        </Col>
        
      </Row>
    </Container>
  );
}

export default Services;
