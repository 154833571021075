import React from "react";
import { Container, Row, Col, Button, Image, Carousel } from "react-bootstrap";
import { easeOut, motion } from "framer-motion";
import "../../homepage/herosection.css";
import heroimagefirst from "../../../../assets/heroimagefirst.svg";
import heroimagesecond from "../../../../assets/heroimagesecond.svg";
import heroimagethird from "../../../../assets/heroimagethird.svg";
import bagmoney from "../../../../assets/bagmoney.svg";

import { Link } from "react-router-dom";

const BadgeSection = () => {
  return (
    <div className="circleradiusdiv">
      <Image src={bagmoney} />
      Adept for All
    </div>
  );
};

const GetStartedBtn = () => {
  return (
    <Button
      variant="primary"
      style={{
        backgroundColor: "#F14810",
        borderColor: "#F14810",
        borderRadius: 0.3 + "rem",
      }}
      className="button"
      size="md"
    >
      <span
        style={{
          fontSize: 0.8 + "rem",
          marginLeft: 0.6 + "rem",
          marginRight: 0.6 + "rem",
        }}
      >
        Get Started
      </span>
    </Button>
  );
};

function HeroSection() {
  return (
    <Container fluid id="hero" className="herosection">
      <Carousel controls={false} indicators={true} style={{ width: 100 + "%" }}>
        <Carousel.Item interval={5000}>
          <Row>
            <Col xs={12} md={6} lg={6} className="herosectionleft">
              <motion.div
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: -5, opacity: 1 }}
                transition={{
                  duration: 0.3,
                  delay: 0.2,
                  timingFunction: easeOut,
                }}
              >
                <div className="heroText">
                  <BadgeSection />
                  <h1 style={{ Color: "white" }}>
                    <strong>
                      Customized{" "}
                      <span style={{ color: "#F14810" }}>
                        Cross-Functional payments
                      </span>{" "}
                      through the banking system.
                    </strong>
                  </h1>
                  <p>
                    Receive, track, and monitor recurring payments from all your
                    clients. No human intervention, No paper confirmation.
                  </p>
                  {/* <Link to="/ourservice">
                    <GetStartedBtn />
                  </Link> */}
                  <Link to="/#">
                    <GetStartedBtn />
                  </Link>
                </div>
              </motion.div>
            </Col>
            <Col xs={12} md={6} lg={6} className="herosectionright">
              <motion.div
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: -5, opacity: 1 }}
                transition={{
                  duration: 0.7,
                  delay: 0.5,
                  timingFunction: easeOut,
                }}
              >
                <center>
                <div className="image-container">
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{
                        duration: 0.3,
                        delay: 0.8,
                        timingFunction: easeOut,
                      }}
                    >
                      <center>
                        <Image src={heroimagefirst} width={510} fluid />
                      </center>
                    </motion.div>
                  </div>
                </center>
              </motion.div>
            </Col>
          </Row>
        </Carousel.Item>
        <Carousel.Item interval={5000}>
          <Row>
            <Col xs={12} md={6} lg={6} className="herosectionleft">
              <motion.div
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: -5, opacity: 1 }}
                transition={{
                  duration: 0.3,
                  delay: 0.2,
                  timingFunction: easeOut,
                }}
              >
                <div className="heroText">
                  <BadgeSection />
                  <h1>
                    <strong>
                      We're{" "}
                      <span style={{ color: "#F14810" }}>
                        connecting every utility
                      </span>{" "}
                      to a bank account!
                    </strong>
                  </h1>
                  <p>
                    Receive, track, and monitor recurring payments from all your
                    clients. No human intervention, No paper confirmation.
                  </p>
                  <Link to="/ourservice">
                    <GetStartedBtn />
                  </Link>
                </div>
              </motion.div>
            </Col>
            <Col xs={12} md={6} lg={6} className="herosectionright">
              <motion.div
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: -5, opacity: 1 }}
                transition={{
                  duration: 0.7,
                  delay: 0.5,
                  timingFunction: easeOut,
                }}
              >
                <center>
                  <div className="image-container">
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{
                        duration: 0.3,
                        delay: 0.8,
                        timingFunction: easeOut,
                      }}
                    >
                      <center>
                        <Image src={heroimagethird} width={380} fluid />
                      </center>
                    </motion.div>
                  </div>
                </center>
              </motion.div>
            </Col>
          </Row>
        </Carousel.Item>
        <Carousel.Item interval={5000}>
          <Row>
            <Col xs={12} md={6} lg={6} className="herosectionleft">
              <motion.div
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: -5, opacity: 1 }}
                transition={{
                  duration: 0.3,
                  delay: 0.2,
                  timingFunction: easeOut,
                }}
              >
                <div className="heroText">
                  <BadgeSection />
                  <h1>
                    <strong>
                      We're{" "}
                      <span style={{ color: "#F14810" }}>
                        connecting every utility
                      </span>{" "}
                      to a bank account!
                    </strong>
                  </h1>
                  <p>
                    Receive, track, and monitor recurring payments from all your
                    clients. No human intervention, No paper confirmation.
                  </p>
                  <Link to="/ourservice">
                    <GetStartedBtn />
                  </Link>
                </div>
              </motion.div>
            </Col>
            <Col xs={12} md={6} lg={6} className="herosectionright">
              <motion.div
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: -5, opacity: 1 }}
                transition={{
                  duration: 0.7,
                  delay: 0.5,
                  timingFunction: easeOut,
                }}
              >
                <center>
                  <div className="image-container">
                    <Image src={heroimagesecond} width={510} fluid />
                  </div>
                </center>
              </motion.div>
            </Col>
          </Row>
        </Carousel.Item>
      </Carousel>
    </Container>
  );
}

export default HeroSection;
